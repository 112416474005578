<template>
  <dog-dialog
    ref="dogDialog"
    width="400px"
    :title="isEdit ? '编辑应用' : '新增应用'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import applicationService from '@/api/service/application.js';
  import companyService from '@/api/service/company';
  import singleImgUpload from '@/components/singleImgUpload.vue';

  export default {
    name: 'addApplicationDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '应用名',
              prop: 'appName',
              rules: [this.$formRules.required('请输入应用名')]
            }
          },
          {
            type: 'input',
            formItem: {
              label: '应用ID',
              prop: 'appId',
              rules: [this.$formRules.required('请输入应用ID')]
            }
          },
          {
            type: 'select',
            formItem: {
              label: '所属公司',
              prop: 'companyId'
            },
            attrs: {
              service: companyService.getAllCompany
              // clearable: false
            }
          },
          {
            type: 'input',
            formItem: {
              label: '应用密钥',
              prop: 'appSecret'
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'appQrCode',
              label: '应用二维码'
            },
            component: singleImgUpload
          },
          {
            type: 'input',
            formItem: {
              label: '应用描述',
              prop: 'appDesc',
              rules: []
            },
            attrs: {
              type: 'textarea',
              rows: 3
            }
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          applicationService.getApplicationDetail({ id }).then((res) => {
            this.formData = res;
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return applicationService[
              this.isEdit ? 'editApplication' : 'addApplication'
            ](this.formData);
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
