import baseService from '../baseService';
import companyService from './company';
const request = baseService('application');
import { array_find } from '@/utils/methods/common';

export default {
  getApplicationList(params) {
    return Promise.all([
      request('/getApplicationList', params, 'get'),
      companyService.getAllCompany()
    ]).then((res) => {
      res[0].list.forEach((item) => {
        item.companyName = array_find(res[1], 'value', item.companyId).label;
      });
      return res[0];
    });
  },
  deleteApplications(params) {
    return request('/deleteApplications', params, 'delete');
  },
  addApplication(params) {
    return request('/addApplication', params, 'post');
  },
  editApplication(params) {
    return request('/editApplication', params, 'put');
  },
  getApplicationDetail(params) {
    return request('/getApplicationDetail', params, 'get');
  }
};
